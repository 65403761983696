import React, {useEffect, useMemo, useState} from "react";
import classes from "./NotificationsList.module.css";
import useUserContext from "../../hooks/useUserContext";
import {getThemes, getUserNotifications} from "../../functions/api";
import {formatDate} from "../../functions/utils";
import Pagination from '@mui/material/Pagination';
import usePagination from '../../hooks/usePagination';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {disableNotification, repeatNotification, editNotification} from '../../functions/api'
import useStatusModalHook from "../../hooks/useStatusModalHook";
import MySelect from "../MySelect/MySelect";
import Loader from "../Loader/Loader";
import useContentModalHook from "../../hooks/useContentModalHook";


const testData = [
  {
    id: 1,
    title: 'Programming',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In facilisis pretium mauris vel mattis. Phasellus ut augue auctor, lacinia magna a, efficitur nibh. Donec eget euismod mi, eu ullamcorper urna. Donec rhoncus sit amet lacus nec vehicula. Nullam non rutrum mauris. Ut convallis tortor sed est imperdiet, sed tristique risus dignissim. Praesent blandit rhoncus magna, a vulputate massa sagittis sit amet. Proin vitae porttitor turpis. Nam in molestie erat.',
    created_at: new Date().toString(),
    next_notifications: new Date().toString(),
  }]


const repeated = (id, setStatus, filterNotifications, token) => {
  const clear = () => {
    filterNotifications(id);
  }
  repeatNotification(token, id, setStatus, clear);
}

const disable = (id, setStatus, filterNotifications, token) => {
  const clear = () => {
    filterNotifications(id);
  }
  disableNotification(token, id, setStatus, clear)
}


const ThemeSelect = ({themes, theme, saveTheme}) => {

  return (
    <label style={{position: "relative", display: "flex"}}>
      <select style={{
        height: "3em",
        background: "#155466",
        border: "none",
        borderRadius: "10px",
        color: "white",
        paddingLeft: "20px",
        marginTop: "10px",
        outline: "none",
        width: "200px"
      }}
              value={theme.title}
              onChange={event => saveTheme(event.target)}
      >
        {themes.map(theme =>
          <option key={theme.id} value={theme.title}>
            {theme.title}
          </option>
        )}
      </select>
    </label>
  );
};


const NotificatorEditor = ({
                             origTitle,
                             origDescription,
                             notifyTheme,
                             saveData,
                             themes
                           }) => {
  const slicedThemes = themes.slice(1);
  const [title, setTitle] = useState(origTitle || '');
  const [description, setDescription] = useState(origDescription || '');

  const [theme, setTheme] = useState(notifyTheme);

  const saveTheme = (target) => {
    const selectedTheme = slicedThemes.find(t => t.title === target.value);
    if (selectedTheme) {
      setTheme(selectedTheme);
    }
  }

  return (
    <div className={classes.editor}>
      <form className={classes.card_form}>
        <label className={classes.title_area}>
          Заголовок и тема
          <input className={classes.title_input}
                 type="text"
                 value={title}
                 onChange={(e) => {
                   setTitle(e.target.value)
                 }}
                 name="title"/>
        </label>
        <ThemeSelect themes={slicedThemes} theme={theme} saveTheme={saveTheme}/>
        <br/>
        <label className={classes.text_area}>
          Описание:
          <textarea className={classes.textarea_input}
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value)
                    }}
                    name="description">
                    </textarea>
        </label>
        <br/>
        <button className={classes.edit_button}
                onClick={(e) => {
                  e.preventDefault();
                  saveData(title, description, theme);
                }}
                type="button">
          Сохранить
        </button>
      </form>
    </div>
  );
};

const Notification = ({notification, setStatus, filterNotifications, token, themes, reDraw}) => {
  const {title: tO, description: dO, created_at, next_notifications, id, theme_data} = notification;
  const openEditor = useContentModalHook();

  const [title, setTitle] = useState(tO || '');
  const [description, setDescription] = useState(dO || '');
  const [themeData, setThemeData] = useState(theme_data);

  const correctSave = (data, themeTitle) => {
    const {title, description, theme} = data;
    setTitle(title);
    setDescription(description);

    if (themeData != null && themeData.id !== theme) {
      if (theme == null || theme === 0) {
        setThemeData(null);
        reDraw(id, theme, themeTitle);
      } else {
        const newTheme = {
          id: theme,
          title: themeTitle
        };
        setThemeData(newTheme);
        reDraw(id, theme, themeTitle);
      }
    } else if (themeData === null && (theme !== null && theme !== 0)) {
      const newTheme = {
        id: theme,
        title: themeTitle
      };
      setThemeData(newTheme);
      reDraw(id, theme, themeTitle);
    }
  };

  const saveNewData = (title, description, theme) => {
    const data = {
      title,
      description,
      theme: theme.id === 0 ? null : theme.id
    };
    editNotification(token, id, setStatus, data, theme.title, correctSave);
  };

  return (
    <div className={classes.container}>
      <div className={classes.notification_container}>
        <p>{title}</p>
        <p>{description}</p>
        <p>создано: {formatDate(created_at)}</p>
        <p style={{display: "flex"}}>
          напоминание: {formatDate(next_notifications)}
        </p>
        {themeData && (
          <div
            style={{
              justifyContent: "flex-end",
              backgroundColor: "#dbeafe",
              color: "#1e40af",
              padding: "6px 12px",
              borderRadius: "6px",
              fontSize: "14px",
              fontWeight: "bold",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            {themeData.title}
          </div>
        )}
      </div>
      <div className={classes.button}>
        <button onClick={(e) => {
          e.preventDefault();
          repeated(id, setStatus, filterNotifications, token);
        }}>Повторил
        </button>
        <button onClick={(e) => {
          e.preventDefault();
          openEditor(<NotificatorEditor
            origTitle={title}
            origDescription={description}
            saveData={saveNewData}
            notifyTheme={themeData || {id: 0, title: "Нету"}}
            themes={themes}
          />)
        }}>Изменить
        </button>
        <button onClick={(e) => {
          e.preventDefault();
          disable(id, setStatus, filterNotifications, token);
        }}>Больше не показывать
        </button>
      </div>
    </div>
  );
};

const NotificationList = () => {
  const [notifications, setNotifications] = useState([]);
  const {token} = useUserContext();
  const [page, setPage] = useState(1);
  const setStatus = useStatusModalHook();

  const [perPage, setPerPage] = useState(5);  // количество напоминаний на странице для пагинации

  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [selectedSort, setSelectedSort] = useState('next_notifications');
  const [themes, setThemes] = useState([]);

  const reDraw = (id, themeId, themeTitle) => {
    const extractedNotification = notifications.find(el => el.id === id);

    if (themeId === 0 || themeId === null) {
      extractedNotification.theme_data = null;
    } else {
      extractedNotification.theme_data = {
        id: themeId,
        title: themeTitle
      }
    }

    const filteredNotifications = notifications.filter((el) => {
      return el.id !== id;
    });

    setNotifications([...filteredNotifications, extractedNotification]);

  }

  const [theme, setTheme] = useState({id: -1, title: "Все темы"});

  const saveThemes = (themes) => {
    setThemes([{id: -1, title: "Все темы"}, {id: 0, title: "Без темы"}, ...themes])
  }

  const saveTheme = (target) => {
    const selectedTheme = themes.find(t => t.title === target.value);
    if (selectedTheme) {
      setTheme(selectedTheme);
    }
  }

  const endLoading = () => {
    setIsLoading(false);
  }

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      getUserNotifications(token, setNotifications, endLoading, setStatus);
      getThemes(token, saveThemes, setStatus)
    }
  }, [token]);

  const filterSortedNotifications = useMemo(() => {
    let notifications_ = notifications.filter((el) => {
      return el.title.toLowerCase().includes(filterText.toLowerCase()) ||
        el.description.toLowerCase().includes(filterText.toLowerCase());
    })

    if (theme.id >= 1) {
      notifications_ = notifications.filter((el) => {
        return el.theme_data && el.theme_data.id === theme.id;
      });
    } else if (theme.id === 0) {
      notifications_ = notifications.filter((el) => {
        return el.theme_data == undefined || el.theme_data === null;
      });
    }

    return notifications_;
  }, [filterText, notifications, theme]);

  const count = Math.ceil(filterSortedNotifications.length / perPage);
  const _DATA = usePagination(filterSortedNotifications, perPage);

  useEffect(() => {
    if (page > count) {
      setPage(count);
      _DATA.jump(count);
    } else if (page <= 0) {
      setPage(1);
      _DATA.jump(1);
    }
    _DATA.jump(page);
  }, [filterSortedNotifications]);

  const filterNotifications = (id) => {
    const notification_ = notifications.filter(el => el.id !== id);
    setNotifications(notification_);
  }

  const sortList = (sort, direction) => {
    if (notifications.length >= 1) {
      setSelectedSort(sort);
      let notifications_ = [...notifications].sort((prev, cur) => {
        const prevDate = new Date(prev[sort]);
        const curDate = new Date(cur[sort]);
        // prev < cur
        if (prevDate < curDate) {
          return -1
        }
        // prev > cur
        else if (prevDate > curDate) {
          return 1
        }
        // prev == cur
        return 0;
      });
      if (direction === 'down') notifications_ = notifications_.reverse();
      setNotifications(notifications_);
    }
  }

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const NotificationListRaw = () => {
    if (isLoading) return <div style={{display: 'flex', justifyContent: 'center', marginTop: 150}}>
      <Loader/></div>
    if (notifications.length !== 0) {
      return (
        <div>
          <h2>Ваши напоминания</h2><br/>

          <div className={classes.filter_sorted}>
            <input className={classes.filter}
                   type='text'
                   placeholder='поиск'
                   value={filterText}
                   onChange={event => setFilterText(event.target.value)}
            />
            <MySelect
              value={selectedSort}
              onChange={sortList}
              defaultValue={selectedSort}
              options={[
                {value: 'created_at', name: 'по дате создания'},
                {value: 'next_notifications', name: 'по дате напоминания'},
              ]}
            />
          </div>

          <div>
            <ThemeSelect themes={themes} theme={theme} saveTheme={saveTheme}/>
          </div>

          <br/>

          <ThemeProvider theme={darkTheme}>
            <Pagination
              count={count}
              page={page}
              size="large"
              color="primary"
              variant="outlined"
              onChange={handleChange}
            />
          </ThemeProvider>
          {_DATA.currentData().map((notification) =>
            <Notification notification={notification}
                          setStatus={setStatus}
                          filterNotifications={filterNotifications}
                          key={notification.id}
                          token={token}
                          themes={themes}
                          reDraw={reDraw}
            />)
          }
          {_DATA.currentData().length !== 0 ? <ThemeProvider theme={darkTheme}>
            <Pagination
              count={count}
              page={page}
              size="large"
              color="primary"
              variant="outlined"
              onChange={handleChange}
            />
          </ThemeProvider> : <h1>Ничего не найдено!</h1>}
        </div>
      )
    }
    return <h2>У вас пока нет активного напоминания.</h2>
  }

  if (!token) return <h1>Авторизуйтесь</h1>

  return NotificationListRaw();

}


export default NotificationList;
